/* eslint-disable */
import {Vue} from 'vue-class-component';
import {Getter, Mutation, Action, State} from 'vuex-class';
import {CustomerState} from '@/store/customer/types';
import {ModelAccountState} from '@/store/modelAccount/types';
import { ValidCredentials, ValidEmail, ValidNickName, ValidPassword } from '@/services/validation.service'
import * as locker from "@/store/locker.js"
const namespace: string = 'modelAccount';

export default class ModelAccountEmailConfirmationPage extends Vue {

	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('createModelAccount', {namespace}) createModelAccount: any;
	@Mutation('modelRememberPassword', {namespace}) modelRememberPassword: any;
	@Getter('getMessage', {namespace}) getMessage: any;
	@Getter('getRequestStatus', {namespace}) getRequestStatus: any;

	modelCredentials = {
		username: '',
		email: '',
		password: '',
		repeatPassword: '',
		isRememberPassword: false,
		isAgreements: false,
		tellUs: ''
	}

	isValid: string = '';
	switchWatchPass: boolean = false;
	switchWatchPassRepeat: boolean = false;
	typeInput: string = 'password';
	typeInputRepeat: string = 'password';
	errorName: string = '';

	toSwitchWatchPassword(type: string) {
		switch (type){
		case 'pass':
			this.switchWatchPass = !this.switchWatchPass;
			if(!this.switchWatchPass) {
				return this.typeInput = 'password';
			} else {
				return this.typeInput = 'text';
			}
		case 'repeat':
			this.switchWatchPassRepeat = !this.switchWatchPassRepeat;
			if(!this.switchWatchPassRepeat) {
				return this.typeInputRepeat = 'password';
			} else {
				return this.typeInputRepeat = 'text';
			}
		default:
			break;
		}
	}

	onSubmit() {
		// if(ValidCredentials(this.modelCredentials, this.modelCredentials.repeatPassword)){
		if( ValidNickName(this.modelCredentials.username) &&
			ValidEmail(this.modelCredentials.email) &&
			ValidPassword(this.modelCredentials.password) &&
			this.modelCredentials.password === this.modelCredentials.repeatPassword
		)
		{
            locker.Show();

			this.getRequestStatus ? this.isValid = 'is-valid': '';

			this.createModelAccount({
				username: this.modelCredentials.username,
				email: this.modelCredentials.email,
				password: this.modelCredentials.password,
				tellUs: this.modelCredentials.tellUs,
				type: 4
			}).then(function () {
                locker.Hide();
            });

			this.modelCredentials.isRememberPassword ? this.modelRememberPassword(true) : false;

		} else {
			!this.getRequestStatus ? this.isValid = 'is-invalid' : '';

			console.log(ValidNickName(this.modelCredentials.username) &&
				ValidEmail(this.modelCredentials.email) &&
				ValidPassword(this.modelCredentials.password) &&
				this.modelCredentials.password === this.modelCredentials.repeatPassword);

			!ValidPassword(this.modelCredentials.password) ? this.errorName = 'pass' :
				!ValidEmail(this.modelCredentials.email) ? this.errorName = 'email' :
					!ValidNickName(this.modelCredentials.username) ? this.errorName = 'login' :
						this.modelCredentials.password !== this.modelCredentials.repeatPassword ? this.errorName = 'repeat' : '';
		}
	}
}
